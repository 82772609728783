export const PROMOTION_LIST_REQUEST = "PROMOTION_LIST_REQUEST";
export const PROMOTION_LIST_SUCCESS = "PROMOTION_LIST_SUCCESS";
export const PROMOTION_LIST_FAIL = "PROMOTION_LIST_FAIL";

export const PROMOTION_DELETE_REQUEST = "PROMOTION_DELETE_REQUEST";
export const PROMOTION_DELETE_SUCCESS = "PROMOTION_DELETE_SUCCESS";
export const PROMOTION_DELETE_FAIL = "PROMOTION_DELETE_FAIL";

export const PROMOTION_CREATE_REQUEST = "PROMOTION_CREATE_REQUEST";
export const PROMOTION_CREATE_SUCCESS = "PROMOTION_CREATE_SUCCESS";
export const PROMOTION_CREATE_FAIL = "PROMOTION_CREATE_FAIL";
export const PROMOTION_CREATE_RESET = "PROMOTION_CREATE_RESET";

export const PROMOTION_UPDATE_REQUEST = "PROMOTION_UPDATE_REQUEST";
export const PROMOTION_UPDATE_SUCCESS = "PROMOTION_UPDATE_SUCCESS";
export const PROMOTION_UPDATE_FAIL = "PROMOTION_UPDATE_FAIL";
export const PROMOTION_UPDATE_RESET = "PROMOTION_UPDATE_RESET";

export const PROMOTION_DETAILS_REQUEST = "PROMOTION_DETAILS_REQUEST";
export const PROMOTION_DETAILS_SUCCESS = "PROMOTION_DETAILS_SUCCESS";
export const PROMOTION_DETAILS_FAIL = "PROMOTION_DETAILS_FAIL";

export const PROMOTION_TOGGLE_REQUEST = "PROMOTION_TOGGLE_REQUEST";
export const PROMOTION_TOGGLE_SUCCESS = "PROMOTION_TOGGLE_SUCCESS";
export const PROMOTION_TOGGLE_FAIL = "PROMOTION_TOGGLE_FAIL";

// Path: frontend/src/reducers/promotionReducers.js
