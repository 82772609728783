import { createStore, combineReducers, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "@redux-devtools/extension";
import {
  productListReducer,
  productDetailsReducer,
  productDeleteReducer,
  productCreateReducer,
  productUpdateReducer,
  productCategoryReducer,
  productPromotionReducer,
} from "./reducers/productReducers";

import {
  categoryCreateReducer,
  categoryListReducer,
  categoryUpdateReducer,
  categoryDeleteReducer,
  categoryDetailsReducer,
} from "./reducers/categoryReducers";

import {
  promotionListReducer,
  promotionDetailsReducer,
  promotionDeleteReducer,
  promotionCreateReducer,
  promotionUpdateReducer,
  promotionToggleReducer,
} from "./reducers/promotionReducers";

import { cartReducer } from "./reducers/cartReducers";
import {
  userLoginReducer,
  userRegisterReducer,
  userDetailsReducer,
  userUpdateProfileReducer,
  userListReducer,
  userDeleteReducer,
  userUpdateReducer,
  userForgotPasswordReducer,
  userResetPasswordReducer,
} from "./reducers/userReducers";

import {
  orderCreateReducer,
  orderDetailsReducer,
  orderDeliverReducer,
  orderListMyReducer,
  orderListReducer,
} from "./reducers/orderReducers";
import {
  bannerViewReducer,
  bannerUploadReducer,
  bannerDeleteReducer,
  smallBannerViewReducer,
  smallBannerUploadReducer,
  smallBannerDeleteReducer,
  allSmallBannersReducer,
} from "./reducers/bannerReducers";

const reducer = combineReducers({
  productList: productListReducer,
  productDetails: productDetailsReducer,
  productDelete: productDeleteReducer,
  productCreate: productCreateReducer,
  productUpdate: productUpdateReducer,
  productCategory: productCategoryReducer,
  productPromotion: productPromotionReducer,

  categoryList: categoryListReducer,
  categoryCreate: categoryCreateReducer,
  categoryDelete: categoryDeleteReducer,
  categoryUpdate: categoryUpdateReducer,
  categoryDetails: categoryDetailsReducer,

  promotionList: promotionListReducer,
  promotionDetails: promotionDetailsReducer,
  promotionDelete: promotionDeleteReducer,
  promotionCreate: promotionCreateReducer,
  promotionUpdate: promotionUpdateReducer,
  promotionToggle: promotionToggleReducer,

  cart: cartReducer,
  userLogin: userLoginReducer,
  userRegister: userRegisterReducer,
  userDetails: userDetailsReducer,
  userUpdateProfile: userUpdateProfileReducer,
  userList: userListReducer,
  userDelete: userDeleteReducer,
  userUpdate: userUpdateReducer,
  forgotPassword: userForgotPasswordReducer,
  resetPassword: userResetPasswordReducer,

  orderCreate: orderCreateReducer,
  orderDetails: orderDetailsReducer,
  orderListMy: orderListMyReducer,
  orderList: orderListReducer,
  orderDeliver: orderDeliverReducer,

  bannerView: bannerViewReducer,
  bannerUpload: bannerUploadReducer,
  bannerDelete: bannerDeleteReducer,

  allSmallBanners: allSmallBannersReducer,
  smallBannerView: smallBannerViewReducer,
  smallBannerUpload: smallBannerUploadReducer,
  smallBannerDelete: smallBannerDeleteReducer,
});

const cartItemsFromStorage = localStorage.getItem("cartItems")
  ? JSON.parse(localStorage.getItem("cartItems"))
  : [];

const userInfoFromStorage = localStorage.getItem("userInfo")
  ? JSON.parse(localStorage.getItem("userInfo"))
  : null;

const shippingAddressFromStorage = localStorage.getItem("shippingAddress")
  ? JSON.parse(localStorage.getItem("shippingAddress"))
  : {};

const initialState = {
  cart: {
    cartItems: cartItemsFromStorage,
    shippingAddress: shippingAddressFromStorage,
  },
  userLogin: { userInfo: userInfoFromStorage },
};

const middleware = [thunk];
const store = createStore(
  reducer,
  initialState,
  composeWithDevTools(applyMiddleware(...middleware))
);

export default store;
